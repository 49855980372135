import { toast } from 'react-toastify';
import { IOption } from '@novo-electronique/react-forms';
import i18n from 'i18next';

import { ActiveState } from '@common/constants/member';
import { MemberNotificationCode } from '@common/constants/notification';
import { IContribution } from '@common/models/contribution';
import { IEmailFilter } from '@common/models/email-filter';
import { IMember } from '@common/models/member';
import {
  activeStateValueProvider,
  agDelegateStatusProvider,
  genderValueProvider,
  jobTypeValueProvider,
  levelOfSchoolingValueProvider,
  memberStatusValueProvider,
  otherMemberStatusProvider,
  provinceValueProvider,
  roleValueProvider,
  schoolingValueProvider,
} from '@common/value-provider';
import apiClient from '@modules/shared/api-client';
import { valueProviderToOptions } from '@modules/shared/components/Form/utils';
import { handleServiceException } from '@modules/shared/service-error-handler';

export function saveMember(member: IMember): Promise<IMember> {
  return apiClient.post<IMember>('/member', member).catch(handleServiceException);
}

export function getMember(id: string): Promise<IMember> {
  return apiClient.get<IMember>(`/member/${id}`).catch(handleServiceException);
}

export function getMembersCount(
  activeState: ActiveState,
  emailFilters: Array<IEmailFilter>,
): Promise<number> {
  return apiClient
    .post<number>(`/member/all/count`, { activeState: activeState, emailFilters: emailFilters })
    .then((count) => +count)
    .catch(handleServiceException);
}

export function getMembersToArchiveCount(): Promise<number> {
  return apiClient
    .get<number>(`/member/all/archive/count`)
    .then((count) => +count)
    .catch(handleServiceException);
}

export function getMemberContributions(memberId: string, period: string): Promise<IContribution[]> {
  return apiClient
    .get<IContribution[]>(`/member/${memberId}/contribution/${period}`)
    .catch(handleServiceException);
}

export function saveMemberContribution(contribution: IContribution): Promise<IContribution> {
  return apiClient
    .post<IContribution>(`/member/${contribution.member.id}/contribution`, contribution)
    .catch(handleServiceException);
}

export function sendUpdateRequests(count: number, emailFilters): Promise<void> {
  return apiClient
    .post<void>('/member/all/send-update-request', { emailFilters: emailFilters })
    .catch((e) => {
      console.log(e);
      toast.error(i18n.t('membersPage.updateRequestFailed', { count }));
    });
}

export function sendNotification(
  memberId: string,
  code: MemberNotificationCode,
  { successKey = 'general.notificationSendingSuccess', errorKey = 'errors.default' } = {},
): Promise<void> {
  return apiClient
    .post<string>(`/member/${memberId}/notification`, { code })
    .then((isSent) => {
      isSent?.toString() === 'true' ? toast.success(i18n.t(successKey)) : toast.error(i18n.t(errorKey));
    })
    .catch(() => {
      toast.error(i18n.t(errorKey));
    });
}

export function archiveMembers(): Promise<void> {
  return apiClient
    .post(`/member/all/archive`)
    .then(() => {
      toast.success(i18n.t('membersPage.archiveMembersSuccessfully'));
    })
    .catch(() => {
      toast.error(i18n.t('membersPage.archiveMembersFailed'));
    });
}

export function getMemberByToken(token: string): Promise<IMember> {
  return apiClient.get<IMember>(`/member/update-request/${token}`);
}

export function saveMemberUpdateRequest(member: IMember, token: string): Promise<IMember> {
  return apiClient.post<IMember>(`/member/update-request/${token}`, member).catch(handleServiceException);
}

export const genderOptions = () => {
  return valueProviderToOptions(genderValueProvider);
};

export const jobTypeOptions = () => {
  return valueProviderToOptions(jobTypeValueProvider);
};

export const activeStateOptions = () => {
  return valueProviderToOptions(activeStateValueProvider);
};

export const memberStatusOptions = (activeState?: string): IOption[] => {
  return memberStatusValueProvider
    .values()
    .filter((value) => {
      if (activeState === ActiveState.Active) {
        return value.startsWith('Active');
      } else if (activeState === ActiveState.Inactive) {
        return value.startsWith('Inactive');
      } else {
        return true;
      }
    })
    .map((value) => {
      return {
        key: value,
        value: value,
        label: memberStatusValueProvider.label(value),
      };
    });
};

export const roleOptions = () => {
  return valueProviderToOptions(roleValueProvider);
};

export const schoolingOptions = () => {
  return valueProviderToOptions(schoolingValueProvider);
};

export const levelOfSchoolingOptions = () => {
  return valueProviderToOptions(levelOfSchoolingValueProvider);
};

export const provinceOptions = () => {
  return valueProviderToOptions(provinceValueProvider);
};

export const delegateStatusOptions = () => {
  return valueProviderToOptions(agDelegateStatusProvider);
};

export const otherMemberStatusOptions = () => {
  return valueProviderToOptions(otherMemberStatusProvider);
};
